import { DataView } from 'primereact/dataview';
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';                                 // icons
import 'primeflex/primeflex.css';                                   // css utility
import { Card } from 'primereact/card';
import { TrackFrame } from './components/TrackFrame';
import { Albums, colorData, setlist, surpriseSongs } from './data/setlist';
import React, { useState, useEffect } from 'react';
import { SetlistData } from './types/Setlist';
import { albumSort } from './util/albumSort';
import { SETLIST } from "./types/Track";

function App() {
  // Chart data

  // const genRelativeData = () => {
  //   const albumData = {
  //     "debut": 0,
  //     "fearless": 0,
  //     "speakNow": 0,
  //     "red": 0,
  //     "1989": 0,
  //     "reputation": 0,
  //     "lover": 0, 
  //     "folklore": 0,
  //     "evermore": 0,
  //     "midnights": 0,
  //   }
  //   for (const album in albumData) {
  //     albumData[album] = 
  //   }
  // }

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [albumsToShow, setAlbumsToShow] = useState<{[key in Albums]: SetlistData}>(setlist);
  const [selectedAlbums, setSelectedAlbums] = useState<string[]>([]);
  const [hideSetlistSongs, setHideSetlistSongs] = useState<boolean>(false);
  const [hidePlayedSurprisedSongs, setHidePlayedSurprisedSongs] = useState<boolean>(false);
  const [hideUnplayedSurprisedSongs, setHideUnplayedSurprisedSongs] = useState<boolean>(false);

  const orderedSetlist = albumSort(setlist);
  const albumsToSelect = orderedSetlist.map(album => ({name: album[1].albumName, value: album[0]}));

  useEffect(() => {
      const data = {
          labels: Object.keys(colorData),
          datasets: [
              {
                  data: [540, 325, 702],
                  backgroundColor: Object.values(colorData),
                  hoverBackgroundColor: Object.values(colorData)
              }
          ]
      }
      const options = {
          plugins: {
              legend: {
                  labels: {
                      usePointStyle: true
                  }
              }
          }
      };
      setChartData(data);
      setChartOptions(options);
  }, []);

    const genColumn = (columnNum: number) => {
        return albumSort(albumsToShow)
            .filter(album => album[1].columnNumber === columnNum)
            .map((album) => (
                <React.Fragment key={album[1].albumName}>
                    <Card style={{ backgroundColor: album[1].colorData }}>
                        <h1 style={{ textAlign: 'center', ...album[1].headerCssStyle }}>{album[1].albumName}</h1>
                        <DataView className={album[1].dataViewClassName} value={album[1].setList.filter(song => {
                            if (hideSetlistSongs && song.venue === SETLIST) {
                                return false;
                            }
                            if (hidePlayedSurprisedSongs && song.isPlayed) {
                                return false;
                            }
                            if (hideUnplayedSurprisedSongs && !song.isPlayed && song.venue !== SETLIST) {
                                return false;
                            }
                            return true;
                        })} itemTemplate={TrackFrame} />
                    </Card>
                    <br />
                </React.Fragment>
            ));
    };

  const filterAlbums = (albums: string[]) => {
    if (albums.length === 0) {
      setAlbumsToShow(setlist);
      setSelectedAlbums(albums);
      return;
    }
    
    const filteredAlbumsToShow: {[key: string]: SetlistData} = {};
    const filteredAlbums = orderedSetlist.filter(album => albums.includes(album[0]));
    filteredAlbums.forEach(a => {
      filteredAlbumsToShow[a[0]] = a[1];
    })
    setAlbumsToShow(filteredAlbumsToShow as {[key in Albums]: SetlistData})
    setSelectedAlbums(albums);
    return filteredAlbumsToShow;
  }

  const genHeader = () => (
    <>
      <h1 style={{fontFamily: "era", textAlign: "center"}}>TAYLOR SWIFT: THE ERAS TOUR</h1>
      <h1 style={{fontFamily: "era", textAlign: "center"}}>Surprise Song Tracker</h1>
      <p style={{fontSize: "16px", textAlign: "center"}}><i>Made by <a href="https://github.com/bunceandbean">bunceandbean</a></i></p>
    </>
  );

  return (
  <>
    <div className="grid">
      <div className="col md:col-8 fadeinleft animation-duration-500 animation-iteration ">
        <Card className="" title={genHeader}>
        </Card> 
      </div>
      <div className="col md:col-4 fadeinright animation-duration-500 animation-iteration">
        <Card className="" title={"Last Played Surprise Songs:"}>
          <DataView value={surpriseSongs} itemTemplate={TrackFrame}></DataView>
          <br></br>
        </Card> 
      </div>
    </div>
    <br />
    <div className="grid">
      <div className='col md:col-12 fadein animation-duration-1000 animation-iteration'>
        <MultiSelect value={selectedAlbums} onChange={(e) => filterAlbums(e.value)} options={albumsToSelect} optionLabel="name" 
          placeholder="Select Album(s)" className="w-full" />
        </div>
    </div>
    <div className="grid">
          <div className="col md:col-4 text-center">
            <label style={{color: "white"}}>
              <input type="checkbox" checked={hideSetlistSongs} style={{
                marginRight: 8
              }} onChange={() => setHideSetlistSongs(!hideSetlistSongs)} />
              Hide Setlist Songs
            </label>
          </div>
          <div className="col md:col-4 text-center">
            <label style={{color: "white"}}>
              <input type="checkbox" checked={hidePlayedSurprisedSongs} style={{
                marginRight: 8
              }} onChange={() => setHidePlayedSurprisedSongs(!hidePlayedSurprisedSongs)} />
              Hide Played Surprised Songs
            </label>
          </div>
          <div className="col md:col-4 text-center">
            <label style={{color: "white"}}>
              <input type="checkbox" checked={hideUnplayedSurprisedSongs} style={{
                marginRight: 8
              }} onChange={() => setHideUnplayedSurprisedSongs(!hideUnplayedSurprisedSongs)} />
              Hide Unplayed Surprised Songs
            </label>
          </div>
        </div>
    <br />
    <div className="grid">
      <div className="col-12 md:col-6 fadeinleft animation-duration-500 animation-iteration">
        {genColumn(1)}
      </div>
      <div className="col-12 md:col-6 fadeinright animation-duration-500 animation-iteration">
        {genColumn(2)}
        {/* Nuking the analytics card for now because it was causing a design headache with the rendering columns */}
        </div>
      

    </div>
      <footer>
        Made by <a href="https://github.com/bunceandbean" style={{color:"inherit"}}>bunceandbean</a>
        <br />
        Beta v3.0
      </footer>
  </>
  );
}

export default App;